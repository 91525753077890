import { ReactComponent as conversations } from './conversations.svg';
import { ReactComponent as contacts } from './contacts.svg';
import { ReactComponent as groups } from './groups.svg';
import { ReactComponent as chatIcon } from './chatIcon.svg';
import { ReactComponent as filter } from './filter.svg';
import { ReactComponent as missedCall } from './missedCall.svg'
import { ReactComponent as cancelledCall } from './red-x.svg'
import { ReactComponent as rejectedCall} from './call-rejected.svg';
import { ReactComponent as TotalCall } from './Total-call.svg';
import { ReactComponent as TotalConnection } from './Total-Connection-Fee.svg';
import { ReactComponent as TotalIncome } from './total-income.svg';
import { ReactComponent as TotalOpeartor } from './Total-Operator.svg';
import { ReactComponent as TotalTechnology } from './Total-Technology .svg';
import { ReactComponent as CallLog } from './call-log.svg';
import { ReactComponent as Call } from './call.svg';
import { ReactComponent as Finance } from './finance.svg';
import { ReactComponent as AdminPanel } from './admin-panel.svg';
import { ReactComponent as MyProfile } from './my-profile.svg';
import { ReactComponent as Outgoing } from './outgoing.svg';
import { ReactComponent as Incoming } from './incoming.svg';
import { ReactComponent as LinkShare } from './link-temporary.svg';
import { ReactComponent as MuteButton } from './muteButton.svg';
import { ReactComponent as ResetPassword } from './resetPassword.svg';
import { ReactComponent as ContactPerson } from './contactPerson.svg';
import { ReactComponent as GroupChat } from './groupChat.svg';
import { ReactComponent as ChatUI } from './chat.svg';
import { ReactComponent as Disconnect } from './disconnectButton.svg';
import { ReactComponent as AcceptCall } from './acceptCall.svg';
import { ReactComponent as AcceptCallSuccess } from './acceptCall_recentPanel.svg';
import { ReactComponent as GridView } from './gridView.svg';
import { ReactComponent as SpeakerView } from './speakerView.svg';
import { ReactComponent as Options } from './options.svg';
import { ReactComponent as SignalPoor } from './signalPoor.svg';
import { ReactComponent as SignalMedium } from './signalMedium.svg';
import { ReactComponent as SignalStrong } from './signalStrong.svg';
import { ReactComponent as VideoOn } from './videoOn.svg';
import { ReactComponent as VideoOff } from './videoOff.svg';
import { ReactComponent as AudioOn } from './audioOn.svg';
import { ReactComponent as AudioOff } from './audioOff.svg';
import { ReactComponent as ScreenShareOn } from './screenShareOn.svg';
import { ReactComponent as ScreenShareRunning } from './share-on.svg';
import { ReactComponent as ScreenShareOff } from './screenShareOff.svg';
import { ReactComponent as AddParticipant } from './addParticipant.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { ReactComponent as VRILogo } from './VriLogo.svg';
import { ReactComponent as CallReceiveIcon } from './call-Receive.svg';
import { ReactComponent as ScreenShareEnd } from './stop-screen-share.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as WindowsLogo } from './windows-logo.svg';
import { ReactComponent as AppleLogo } from './apple-logo.svg';
import { ReactComponent as AppleStoreLogo } from './apple-store-logo.svg';
import { ReactComponent as AndroidLogo } from './android.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as ViewProfile } from './viewProfile.svg';
import { ReactComponent as CopyPaste } from './copyPaste.svg';
import { ReactComponent as Tin } from './tin.svg';
import { ReactComponent as ChatView } from './chatView.svg';
import { ReactComponent as ChangePassword } from './changePassword.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as DownloadV2 } from './downloadV2.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as PlusCircle } from './plusCircle.svg';
import { ReactComponent as EditTin } from './editTin.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as PhoneCall } from './phoneCall.svg';
import { ReactComponent as VideoCamera } from './videoCamera.svg';
import { ReactComponent as GenericUser} from './user-silhouette.svg'

export const AssetSvg = {
  WindowsLogo: WindowsLogo,
  AppleLogo: AppleLogo,
  AppleStoreLogo: AppleStoreLogo,
  AndroidLogo: AndroidLogo,
  Conversations: conversations,
  Contacts: contacts,
  Groups: groups,
  Chat: chatIcon,
  Filter: filter,
  MissedCall: missedCall,
  RejectedCall: rejectedCall,
  CanceledCall: cancelledCall,
  TotalCall,
  TotalIncome,
  TotalConnection,
  TotalOpeartor,
  TotalTechnology,
  CallLog,
  Call,
  Finance,
  AdminPanel,
  MyProfile,
  Outgoing,
  Incoming,
  LinkShare,
  MuteButton,
  ResetPassword,
  ContactPerson,
  GroupChat,
  ChatUI,
  Disconnect,
  AcceptCall,
  AcceptCallSuccess,
  GridView,
  SpeakerView,
  Options,
  SignalPoor,
  SignalMedium,
  SignalStrong,
  VideoOn,
  VideoOff,
  AudioOn,
  AudioOff,
  ScreenShareOn,
  ScreenShareOff,
  AddParticipant,
  SearchIcon,
  VRILogo,
  CallReceiveIcon,
  ScreenShareEnd,
  ScreenShareRunning,
  Trash,
  Edit,
  Delete,
  ViewProfile,
  CopyPaste,
  Tin,
  ChatView,
  ChangePassword,
  Plus,
  Download,
  DownloadV2,
  Refresh,
  PlusCircle,
  EditTin,
  Message,
  PhoneCall,
  VideoCamera,
  GenericUser
}