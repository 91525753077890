import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TwilioError } from 'twilio-video';
import { ActionAuth, AxiosAuth } from '..';
import { AppConstants, PlatformApi, device } from '../../configs';
import { useWebSocket } from '../../pages/hooks';
import { logger } from '../../utils';
import { useAppSelector, VRIAppStateType } from '../Redux';
// import { AssetsAudio } from '../../assets';


export const SocketContext = React.createContext<{
  socketConnection: WebSocket | null;
  loadTwilioToken: () => void;
}>({ socketConnection: null, loadTwilioToken: () => { } });

export function SocketContextProvider(
  props: React.PropsWithChildren<{}>
): JSX.Element {
  const dispatch = useDispatch();
  const { webToken, isLoggedIn } = useAppSelector((state: VRIAppStateType) => ({
    profileId: state.auth.profile?.id,
    isLoggedIn: state.auth.isLoggedIn,
    webToken: state.auth.VRItoken,
  }));
  const webTokenRef = useRef(webToken);
  const { webSocketConnection: socketConnection } = useWebSocket();

  const loadTwilioToken = useCallback(() => {
    console.warn("loadTwilioToken HAS BEEN CALLED");
    logger({ message: 'Getting WebToken' });
    return AxiosAuth.get(PlatformApi.Authentication.Token())
      .then((response) => {
        if (!!response.data && response.status === AppConstants.Api.StatusOK) {
          const token = response.data.token;
          if (webTokenRef.current) {
            logger({ message: 'Updating WebToken' });
            device.updateToken(token);
          } else {
            logger({ message: 'Initiating WebToken' });
            device.setup(token, {
              enableRingingState: true,
              sounds: {
                incoming: 'https://audiocalltest.s3.us-east-2.amazonaws.com/video_reciever_tone.mp3'
              }
            });
            // device.setup(token);

          }
          dispatch(ActionAuth.SetVRItoken(token));
          webTokenRef.current = token;
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          logger({
            message: 'Error: Fetching WebToken failed: ',
            data: error.response,
          });
          throw error;
        }
      });
  }, [dispatch]);

  const socketConnectionMemo = useMemo(
    () => ({ socketConnection, loadTwilioToken }),
    [socketConnection, loadTwilioToken]
  );



  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    loadTwilioToken();
  }, [isLoggedIn, loadTwilioToken]);

  useEffect(() => {
    const handleDeviceOffline = (error: TwilioError) => {
      if (error.code === 31205) {
        loadTwilioToken();
      }
    };
    device.on('error', handleDeviceOffline);

    return () => {
      device.off('error', handleDeviceOffline);
    };
  }, [loadTwilioToken]);

  return (
    <SocketContext.Provider value={socketConnectionMemo}>
      {props.children}
    </SocketContext.Provider>
  );
}
