import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AppConstants, AppRouteUi, CallStatusType } from "../configs";
import { LayoutPrivate, LayoutPublic, PageNotFound, EnableCookiesPage } from ".";
import { ActionVideoCall, useAppDispatch, useAppSelector, VRIAppStateType } from "../core";
import { shallowEqual } from "react-redux";
import { useEffect, useRef } from "react";
import { UserMediaUtils } from "../utils";


export function LayoutApplication(): JSX.Element {
  const location = useLocation();
  const previousLocationRef = useRef('');
  const dispatch = useAppDispatch();

  let hasLocalStorageAccess = true;
  let routePath =
    "/(log|home|chat|jobs|edit-job|calendar|video-conference|video-schedules|settings|profile|incoming-call|room|video|call-log|call-summary";
  routePath +=
    "|internal-call-log|overflow-call-log|inflow-call-log|outflow-call-log|company|company/profile|reseller|phone-number";
  routePath +=
    "|vendors|call-outflow|users|tin|language|language-packs|skill|audit-logs|operators|outgoing-call|call-outgoing|video-outgoing-call|call-failed-media|call-failed-mic";
  routePath +=
    "|video-incoming-call|operator|activity|call-inflow|message|finance/account|finance/company|finance/operator";
  routePath +=
    "|modality|appointment-category|appointment-description|vos-form|download|ivr)";

  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      isLoggedIn: state.auth.isLoggedIn,
      role: state.auth.profile?.userRole,
      videoCallStatus: state.videoCall.CallStatus,
    }),
    shallowEqual
  );

  useEffect(() => {
    const currentPath = location.pathname;
    if (
      (currentPath === AppRouteUi.Home.Root ||
        currentPath === AppRouteUi.CallLog.Root ||
        currentPath === AppRouteUi.Jobs.Root) &&
      previousLocationRef.current.includes('call')
    ) {
      UserMediaUtils.stopAllMedia();
      dispatch(ActionVideoCall.setTracks(null));
    }
    previousLocationRef.current = currentPath;
  }, [dispatch, location]);

  useEffect(() => {
    if (
      data.videoCallStatus?.current === CallStatusType.Failed ||
      data.videoCallStatus?.current === CallStatusType.Completed ||
      data.videoCallStatus?.current === CallStatusType.Canceled ||
      data.videoCallStatus?.current === CallStatusType.OperatorNotFound ||
      data.videoCallStatus?.current === CallStatusType.UserNotFound ||
      data.videoCallStatus?.current === CallStatusType.PreparingCallSummary
    ) {
      UserMediaUtils.stopAllMedia();
      dispatch(ActionVideoCall.setTracks(null));
    }
  }, [data.videoCallStatus, dispatch]);
  
  try {
    window.localStorage.getItem('');
  } catch(error) {
    console.error(error);
    hasLocalStorageAccess = false;
  }

  if(!hasLocalStorageAccess) {
    return <EnableCookiesPage/>;
  }

  return (
    <>
      <Switch>
        {data.isLoggedIn && data.role === AppConstants.UserRoles.Consumer ? (
          <Redirect exact from="/" to={AppRouteUi.Consumer.Root} />
        ) : data.isLoggedIn && data.role !== AppConstants.UserRoles.Operator ? (
          <Redirect exact from="/" to={AppRouteUi.Home.Root} />
        ) : data.isLoggedIn && data.role === AppConstants.UserRoles.Operator ? (
          <Redirect exact from="/" to={AppRouteUi.CallLog.Root} />
        ) : (
          <Redirect exact from="/" to={AppRouteUi.Home.Root} />
        )}
        <Route
          path="/(login|activate-account|forgot-password|reset-password|change-password|make-schedule|video-rejoin|consumer|lobby|external-login|guest|call-ended|404)"
          component={LayoutPublic}
        />
        <Route path={routePath} component={LayoutPrivate} />
        <Route path="**" component={PageNotFound} />
      </Switch>
    </>
  );
}
