import React from "react";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import { ActionVideoCall, useAppDispatch, useAppSelector, VRIAppStateType } from "../../core";
import { CallLogTableDataWeb, ModalCalendarUpcomingSchedule, ModalCallSummary, } from "..";
import { AssetSvg } from "../../assets";
import { Pagination } from "../../components/common";
import { AppRouteUi, CallStatusType, Modals, PaginationSettings, topLoaderProgress, } from "../../configs";
import { ConsumerConferenceData } from "../../models";
import { CallSummaryResponseModel, RecentCallResponseModel, } from "../../models/api/Call";
import { ApiSchemaConferenceData } from "../../models/api/videoconference";
import { useFunctionalityCallSummary, useFunctionalityVideoConferenceCall, useFunctionalityVideoScheduler, } from "../../pages/hooks";
import { useFunctionalityRecentCall } from "../../pages/hooks/functionalityRecentCalls";
import DataTable from "react-data-table-component";
import { BootstrapUtils, UserMediaUtils } from "../../utils";
import { Spinner } from "../../assets/Spinner";
import { toast } from "react-toastify";

type Props = {
  type: string;
  //show: boolean | false;
  loaderProgress: number;
  setLoaderProgress: (value: number) => void;
  //setShow: (value: boolean) => void;
  callSummaryData?: CallSummaryResponseModel;
  setCallSummaryData: (value: CallSummaryResponseModel) => void;
};

const getCurrentLocalTimeByTimeZone = (timeZone: string) => {
  const currentDate = new Date()
  const currentDateWithUserTimeZone = currentDate.toLocaleString('en-US', { timeZone: timeZone })
  return new Date(currentDateWithUserTimeZone)
}
export function RecentCalls(props: Props): JSX.Element {
  const { type, callSummaryData, setCallSummaryData } = props;
  const dispatch = useAppDispatch();
  const { twilioDeviceStatus, isBackdatedVersion } = useAppSelector((state: VRIAppStateType) => state.common);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [show, setShow] = useState(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState("");
  const [showUpcomingSchedule, setShowUpcomingSchedule] = useState<boolean>(false);
  const [upcomingScheduleDetails, setUpcomingScheduleDetails] = useState({});
  const [pageIndexForConf, setPageIndexForConf] = useState(PaginationSettings.initialPageIndex);
  const [pageSizeForConf, setPageSizeForConf] = useState(PaginationSettings.initialPageSize);
  const [pagedRecentCallsList, setPagedRecentCallsList] = useState<RecentCallResponseModel[]>([]);
  const [pagedCallListCount, setPagedCallListCount] = useState(0);
  const [loadingForStartCall, setLoadingForStartCall] = useState<boolean>(false);
  const [roomIdForStartCall, setRoomIdForStartCall] = useState<string>("");


  const { conferenceList, conferenceListCount } = useFunctionalityVideoScheduler(
    pageSizeForConf,
    pageIndexForConf,
    props.type
  );

  const { onCreateRoom, onCreateScheduleCall } =
    useFunctionalityVideoConferenceCall(setLoadingForStartCall);
  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const handleStartRoomClick = async (roomId: string, operatorId: string) => {
    if (twilioDeviceStatus === 'offline') {
      toast.error(
        'Please, try again after few seconds'
      );
      return;
    }
    const tracks = await UserMediaUtils.startMedia();
    if (!tracks) {

      //console.trace("Redirecting:");
      history.push({
        pathname: AppRouteUi.OutgoingCallFailedForMedia.Root,
      });
      return;
    }
    dispatch(ActionVideoCall.setTracks(tracks));
    startRoom(roomId, operatorId);
  }
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const onChangePageIndexForConf = (index: number) => {
    setPageIndexForConf(index);
  };
  const onChangePageSizeForConf = (size: number) => {
    setPageSizeForConf(size);
  };
  const { LoadRecentCall } = useFunctionalityRecentCall({
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
  });
  const { LoadById } = useFunctionalityCallSummary();
  const history = useHistory();
  const onEdit = (callId: string) => {
    setLoaderProgress(topLoaderProgress.start);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>> LoadbyId 1 from RecentCalls");
    LoadById(callId).then((data) => {
      setLoaderProgress(topLoaderProgress.complete);
      if (data) setCallSummaryData(data);
      setShow(true);
    });
  };
  useEffect(() => {
    LoadRecentCall().then((r) => {
      if (r?.data.data) setPagedRecentCallsList(r?.data.data);
      if (r?.data.data) setPagedCallListCount(r?.data.count);
    });
  }, []);
  const rootUrl = process.env.REACT_APP_CLIENT_ROOT || "";
  const startRoom = (roomId: string, assignedOperatorId: string) => {
    setLoadingForStartCall(true);
    setRoomIdForStartCall(roomId);
    if (assignedOperatorId) {
      onCreateScheduleCall(roomId, assignedOperatorId);
    } else {
      onCreateRoom(roomId);
    }
  };

  const handleConferenceView = (conference: ApiSchemaConferenceData) => {
    const consumerConferenceDate: ConsumerConferenceData = {
      start: conference.start,
      end: conference.end,
      roomId: conference.roomId,
    };
    setUpcomingScheduleDetails(consumerConferenceDate);
    setShowUpcomingSchedule(true);
  };

  const recentCallIcon = (row: RecentCallResponseModel) => {
    if (
      row.callStatusType === CallStatusType.Missed
    ) {
      return <AssetSvg.MissedCall />;
    } else if (row.callStatusType === CallStatusType.Rejected) {
      return <AssetSvg.RejectedCall style={{width: '13px'}} />;
    }
    else if (row.callStatusType === CallStatusType.Canceled) {
      return <AssetSvg.CanceledCall style={{width: '13px'}}/>;
    }
    else {
      return <AssetSvg.AcceptCallSuccess />;
    }
  };

  const recentColumns = [
    {
      name: "",
      selector: (row: RecentCallResponseModel) => {
        return recentCallIcon(row);
      },
      width: "46px",
      padding: "0",
    },
    {
      name: "Name",
      selector: (row: RecentCallResponseModel) => row.name,
    },
    {
      name: "Company",
      selector: (row: RecentCallResponseModel) => row.companyName,
    },
    {
      name: "Call Duration",
      selector: (row: RecentCallResponseModel) => row.callDuration,
    },
    {
      name: "Call Date",
      selector: (row: RecentCallResponseModel) => row.callDate,
    },
    {
      name: "Actions",
      allowOverflow: true,
      selector: (row: RecentCallResponseModel) => {
        return (
          <>
            <div
              className="btn svg-color mx-auto"
              title="view"
              onClick={() => {
                setLoaderProgress(topLoaderProgress.start);
                console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>> LoadbyId 2 from recentCalls ");
                LoadById(row.id).then((data) => {
                  setLoaderProgress(topLoaderProgress.complete);
                  if (data) setCallSummaryData(data);
                  setShow(true);
                });
              }}
            >
              <span className="flex-shrink-0 mr-2">
                <AssetSvg.ViewProfile />
              </span>
            </div>
          </>
        );
      },
    },
  ];

  const recentColumnsForConf = [
    {
      name: "Date",
      selector: (row: ApiSchemaConferenceData) =>
        moment(row.start).format("DD-MM-YYYY") ?? "-",
    },
    {
      name: "Time",
      selector: (row: ApiSchemaConferenceData) =>
        moment(row.start).format("hh:mm:ss a") ?? "-",
    },
    {
      name: "Chatroom link",
      selector: (row: ApiSchemaConferenceData) => row.roomId ?? "-",
    },
    {
      name: "Emails",
      selector: (row: ApiSchemaConferenceData) => row.emails ?? "-",
    },
    {
      name: "Phone",
      selector: (row: ApiSchemaConferenceData) => row.phones ?? "-",
    },
    {
      name: "Language",
      selector: (row: ApiSchemaConferenceData) => row.language ?? "-",
    },

    {
      name: "Actions",
      allowOverflow: true,
      selector: (row: ApiSchemaConferenceData) => {
        return (
          <>
            <button
              data-toggle="modal"
              type="button"
              className="btn btn-link py-0 svg-color"
              title="View"
              onClick={() => handleConferenceView(row)}
              data-target={BootstrapUtils.GetSelectorById(
                Modals.CalendarUpcomingScheduleModal
              )}
            >
              <span>
                <AssetSvg.Plus />
              </span>
            </button>
            <button
              className=" btn btn-outline-dark border-grey ml-2"
              disabled={isBackdatedVersion || new Date(row?.start!) > getCurrentLocalTimeByTimeZone(row?.timeZone)}
              onClick={() => {
                handleStartRoomClick(row.roomId, row.assignedOperatorId);
              }}
            >
              <div className="d-flex w-100">
                <FontAwesomeIcon icon={faVideo} className="mr-2" />
                {loadingForStartCall && roomIdForStartCall === row.roomId ? (
                  <Spinner fillHtmlCode="#000" />
                ) : (
                  "Start Room"
                )}
              </div>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="recentCall-table-container d-md-block">
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {props.type === "conference" ? (
        <div className="component-card mb-3">
          <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
            <div>
              <span>Upcoming Schedules</span>
            </div>
          </div>
          {props.type === "conference" && conferenceList.length >= 1 ? (
            <div className="component-card-body border ">
              <div className="table-responsive d-md-block d-lg-block d-xl-block">
                <DataTable
                  columns={recentColumnsForConf}
                  data={conferenceList}
                  highlightOnHover={true}
                  pointerOnHover={true}
                  onRowClicked={handleConferenceView}
                />
              </div>
              {conferenceListCount ? (
                <Pagination
                  pageSize={pageSizeForConf}
                  pageIndex={pageIndexForConf}
                  count={conferenceListCount}
                  onChangePageIndex={onChangePageIndexForConf}
                  onChangePageSize={onChangePageSizeForConf}
                />
              ) : null}
            </div>
          ) : (
            <div className="component-card-body border">
              <div className="row align-items-center justify-content-center  py-5 w-100">
                <h2 className="font-weight-normal">
                  Make your first{" "}
                  <a href="" className="text-site">
                    call now
                  </a>
                </h2>
              </div>
            </div>
          )}
        </div>
      ) : props.type === "search" && pagedRecentCallsList.length >= 1 ? (
        <div className="row">
          <CallLogTableDataWeb
            loaderProgress={loaderProgress}
            pageHeader="Recent Calls"
            tableData={pagedRecentCallsList}
            tableHeaders={recentColumns}
            pageIndex={pageIndex}
            pageSize={pageSize}
            count={pagedCallListCount}
            onChangePageIndex={onChangePageIndex}
            onChangePageSize={onChangePageSize}
            isPageEnabled={false}
            setCallSummaryData={setCallSummaryData}
            setLoaderProgress={setLoaderProgress}
            setShow={setShow}
          />
          <ModalCallSummary
            summary={callSummaryData}
            show={show}
            handleShow={setShow}
          />
        </div>
      ) : (
        <div className="component-card mb-4">
          <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left  ">
            <div>
              <span>Recent Calls</span>
            </div>
          </div>
          <div className="component-card-body  border-top border-bottom border-right border-left p-0">
            <div className="row align-items-center justify-content-center  py-5 w-100">
              <h2 className="font-weight-normal">
                Make your first{" "}
                <a href="" className="text-site">
                  call now
                </a>
              </h2>
            </div>
          </div>
        </div>
      )}
      <ModalCalendarUpcomingSchedule
        show={showUpcomingSchedule}
        handleShow={setShowUpcomingSchedule}
        scheduleInfo={upcomingScheduleDetails}
      />
      {/* <ModalInfo show={showRoomLink} handleShow={setShowRoomLink} info={generateRoomLink(roomId)} /> */}
    </div>
  );
}
