import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CallInitiateTabs,
  PlatformType,
  CallType,
  CallStatusType,
  AppConstants,
  AppRouteUi,
  SocketConstants,
  DefaultImageType,
  FileBucketURL,
  PlatformType2,
} from '../../configs';
import { AssetsAudio, AssetsPng } from '../../assets';
import { device } from '../../configs/twilioDevice';
import { useAppSelector, VRIAppStateType } from '../../core';
import { CallContext } from '../../core/Contexts/CallContext';
import { CallDurationCounter } from '../../features';
import { ApiResponse, AudioCallInitiateParameter } from '../../models';
import {
  ApiSchemaFilterOperatorRequest,
  FilterOperatorResponseModel,
} from '../../models/api/Operator';
import {
  useFunctionalityCompanyStatus,
  useFunctionalityImageView,
  useFunctionalityOperator,
  useFunctionalityUser2,
  useFunctionalityUserGetUserById,
} from '../hooks';
import { ActionAudioCall } from '../../core/Redux/Slices/sliceCall';
import { useFunctionalityCalls } from '../hooks/functionalityCalls';
import { AssetSvg } from '../../assets';
import { ModalHangupCall } from '../../features/modals/common/hangupCallModal';
import { unstable_batchedUpdates } from 'react-dom';
import { logger } from '../../utils';
import { NetworkConnection } from '../../components';



export function PageOutgoingCall(): JSX.Element {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioEnabled, setAudioEnabled] = useState(true);
  const { isAccepted, setIsAccepted } = React.useContext(CallContext);
  const { filteredOperatorList } = useFunctionalityOperator({});
  const { onUpdate } = useFunctionalityCalls();
  const { getUserStatus } = useFunctionalityUser2({});
  const history = useHistory();
  const { oncreate } = useFunctionalityCalls();
  const [messageOperatorNotFound, setMessageOperatorNotFound] =
    useState<string>(
      'Operator is not available at this moment or busy with other call. Please try again later'
    );

  const location = useLocation<{
    operatorFilterParam?: ApiSchemaFilterOperatorRequest;
    audioCallInitiateParam?: AudioCallInitiateParameter;
    callInitiateTab?: string;
    directCallSelectedUserId?: string;
    callType: CallType;
    bookingId?: string;
  }>();
  //console.trace("Redirecting:");
  !location.state && history.push(AppRouteUi.Home.Root);
  
  const [parentCallId, setParentCallId] = useState<string>('');
  const [callForwardCount, setCallForwardCount] = useState<number>(0);
  const currentUserCompanyId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId
  );

  const {
    operatorFilterParam,
    audioCallInitiateParam,
    callInitiateTab,
    directCallSelectedUserId,
    callType,
    bookingId,
  } = location?.state || {};
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = React.useState<string>(
    directCallSelectedUserId || ''
  );
  const [show, setShow] = useState(false);
  const [participantState, setParticipantState] = React.useState({
    prev: 0,
    current: 1,
  });
  const callerInfo = useAppSelector(
    (state: VRIAppStateType) => ({
      uid: state.auth.profile?.id,
      userName: state.auth.profile?.name,
      gender: state.auth.profile?.gender,
      companyName: state.auth.profile?.companyName,
      role: state.auth.profile?.userRole,
    }),
    shallowEqual
  );

  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      // receiverName: state.ui.receiverName,
      callerId: state.ui.callerId,
      receiverId: state.ui.receiverId,
      companyId: state.auth.profile?.companyId,
      audioCallStatus: state.audioCall.audioCallStatus,
      userRole: state.auth.profile?.userRole,
      callType: callType,
    }),
    shallowEqual
  );

  const [callId, setCallId] = useState<string>('');
  const [receiverId, setReceiverId] = useState<string>('');
  const audioCallStatusRef = React.useRef(data.audioCallStatus);
  audioCallStatusRef.current = data.audioCallStatus;

  const { userData: dialUser } = useFunctionalityUserGetUserById({
    companyId: data?.companyId,
    userId: selectedUserId,
  });
  const isCalleeOperator = dialUser?.userRole === AppConstants.UserRoles.Operator;

  const { onCheckCompanyStatusIsActive } = useFunctionalityCompanyStatus();
  const [companyStatusIsActive, setCompanyStatusIsActive] = useState<boolean>();
  const [companyStatusMsg, setCompanyStatusMsg] = useState<string>();

  useEffect(() => {
    const audio = audioRef.current;
    onCheckCompanyStatusIsActive(currentUserCompanyId ?? '')?.then((res) => {
      unstable_batchedUpdates(() => {
        setCompanyStatusIsActive(res?.data);
        setCompanyStatusMsg(res?.message);
      });
    });

    return ()=> {
      audio?.pause();
    }
  }, []);

  React.useEffect(() => {
    if (companyStatusIsActive !== undefined && companyStatusMsg !== undefined) {
      if (companyStatusIsActive) {
        if (callInitiateTab === CallInitiateTabs.searchInterpreter) {
          if (
            data.audioCallStatus?.current === CallStatusType.Rejected &&
            data.audioCallStatus?.prev === CallStatusType.Ringing
          ) {
            dispatch(
              ActionAudioCall.SetAudioCallStatus({
                prev: CallStatusType.Rejected,
                current: CallStatusType.Searching,
              })
            );
          }
          if (
            (data.audioCallStatus?.current === CallStatusType.Searching &&
              data.audioCallStatus?.prev === CallStatusType.Rejected) ||
            (data.audioCallStatus?.current === CallStatusType.Searching &&
              data.audioCallStatus.prev === CallStatusType.Missed) ||
            (data.audioCallStatus?.current === CallStatusType.Searching &&
              data.audioCallStatus.prev === CallStatusType.Initiated) ||
            (data.audioCallStatus?.current === CallStatusType.Searching &&
              data.audioCallStatus.prev === CallStatusType.Zero)
          ) {
            const model = {
              ...operatorFilterParam,
              parentCallId: parentCallId,
              callForwardCount: callForwardCount,
            };

            filteredOperatorList(model || {}, PlatformType.OPI)
              .then((res?: ApiResponse<FilterOperatorResponseModel>) => {
                if (res?.data?.operatorId) {
                  setParentCallId(res.data.callId);
                  setCallForwardCount(res.data.callForwardCount);

                  dispatch(
                    ActionAudioCall.SetAudioCallStatus({
                      prev: CallStatusType.Searching,
                      current: CallStatusType.Initiated,
                    })
                  );

                  console.log(res);
                  setSelectedUserId(res.data?.operatorId);
                  setCallId(res.data?.callId);
                  setReceiverId(res.data?.operatorId);
                  try {
                    audioCallInitiateHandler(
                      res.data.operatorId,
                      audioCallInitiateParam?.operatorLanguageName || '',
                      res.data?.callId
                    );
                  } catch (error) {
                    logger({
                      message: 'Device Connect Error: ',
                      data: error,
                    });
                  }
                  const timer = setTimeout(
                    (function (x) {
                      return function () {
                        if (
                          audioCallStatusRef?.current?.current !==
                            CallStatusType.Ringing &&
                          audioCallStatusRef?.current?.current !==
                            CallStatusType.Completed &&
                          audioCallStatusRef?.current?.current !==
                            CallStatusType.Established
                        ) {
                          dispatch(
                            ActionAudioCall.SetAudioCallStatus({
                              prev: CallStatusType.Initiated,
                              current: CallStatusType.Searching,
                            })
                          );
                        }
                      };
                    })(data),
                    40000
                  );
                  return () => clearTimeout(timer);
                } else {
                  if (res?.data.message)
                    setMessageOperatorNotFound(res.data.message);
                  device.disconnectAll();
                  dispatch(
                    ActionAudioCall.SetAudioCallStatus({
                      prev: CallStatusType.Searching,
                      current: CallStatusType.OperatorNotFound,
                    })
                  );
                  //console.trace("Redirecting:");
                  history.push({
                    pathname: AppRouteUi.OutgoingCallFailed.Root,
                    state: {
                      operatorFilterParam: operatorFilterParam,
                      callInitiateTab: callInitiateTab,
                      audioCallInitiateParam: audioCallInitiateParam,
                      directCallSelectedUserId: directCallSelectedUserId,
                      callType: CallType.Audio,
                      message: res?.data.message,
                    },
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
          // No need, also if 5th calling time process, this block will show msg for OperatorNotFound
          // else if (callForwardCount === 5) {
          //   dispatch(
          //     ActionAudioCall.SetAudioCallStatus({
          //       prev: CallStatusType.Searching,
          //       current: CallStatusType.OperatorNotFound,
          //     })
          //   );
          // }
        }
      } else {
        //console.trace("Redirecting:");
        history.push({
          pathname: AppRouteUi.OutgoingCallFailed.Root,
          state: {
            operatorFilterParam: operatorFilterParam,
            callInitiateTab: callInitiateTab,
            audioCallInitiateParam: audioCallInitiateParam,
            directCallSelectedUserId: directCallSelectedUserId,
            callType: CallType.Audio,
            message: companyStatusMsg,
          },
        });
      }
    }
  }, [data?.audioCallStatus?.current, companyStatusIsActive]);

  React.useEffect(() => {
    if (callInitiateTab === CallInitiateTabs.directCall) {
      getUserStatus(directCallSelectedUserId ?? '').then((d) => {
        if (d == SocketConstants.online) {
          dispatch(
            ActionAudioCall.SetAudioCallStatus({
              prev: CallStatusType.Searching,
              current: CallStatusType.Initiated,
            })
          );
          logger({ message: 'Trying for OPI call'});
          logger({ message: `Caller Id: ${data.callerId}`});
          logger({ message: `Callee Id: ${directCallSelectedUserId}`});
          oncreate({
            callerUserId: data.callerId,
            callStatus: CallStatusType.Ringing,
            platform: PlatformType2.OPI,
            calleeId: directCallSelectedUserId,
            bookingId: bookingId,
            supportType: 1,
          }).then((callId) => {
            try {
              audioCallInitiateHandler(
                directCallSelectedUserId || '',
                '',
                callId!
              );
            } catch (error) {
              logger({ message: 'Device Connect Error: ', data: error });
            }
            setCallId(callId!);
          });
        } else {
          dispatch(
            ActionAudioCall.SetAudioCallStatus({
              prev: CallStatusType.Searching,
              current: CallStatusType.UserNotFound,
            })
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isAccepted)
      dispatch(ActionAudioCall.SetAudioCallStartTime(new Date().toString()));
  }, [isAccepted]);

  const audioCallInitiateHandler = (
    selectedUserId: string,
    selectedLanguage: string,
    pCallId: string
  ) => {
    if (device.activeConnection()) {
      device.disconnectAll();
    }
    device.connect({
      to: selectedUserId,
      //from: callerInfo?.uid ?? "",
      callerId: callerInfo?.uid ?? '',
      callerName: callerInfo?.userName ?? '',
      callerGender: callerInfo.gender ?? '',
      callerRole: callerInfo.role ?? '',
      callerCompanyName: callerInfo.companyName ?? '',
      operatorLanguageName: selectedLanguage ?? '',
      callId: pCallId,
    });
  };

  const audioCallCompleted = () => {
    audioRef.current?.pause();
    dispatch(
      ActionAudioCall.SetAudioCallStatus({
        prev: CallStatusType.Established,
        current: CallStatusType.Completed,
      })
    );
    setIsAccepted(false);
    device.disconnectAll();
  };
  const audioCallCancelled = () => {
    audioRef.current?.pause();
    device.disconnectAll();
    onUpdate(callId, {
      callStatus: CallStatusType.Canceled,
      eventTime: new Date().toISOString(),
      receiverUserId: selectedUserId,
      isCallStarted: false,
    });
    //console.trace("Redirecting:");
    history.push({
      pathname:
        data.userRole === AppConstants.UserRoles.Operator
          ? AppRouteUi.CallLog.Root
          : AppRouteUi.Home.Root,
    });
  };
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const onEndedPlay = (ev: any) => {
    console.log("onEndedPlay is triggered from OutgoingCallPage");
    ev.target.currentTime = 0;
    ev.target.play();
  };

  useEffect(() => {
    logger({ message: "Call Status: ", data: data.audioCallStatus?.current });
    if (data.audioCallStatus?.current === CallStatusType.Ringing) {
      try {
        //console.trace("Redirecting:");
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
          //console.trace("Redirecting:");
          window.history.pushState(null, document.title, window.location.href);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [data.audioCallStatus?.current]);
  useEffect(() => {
    if (data.audioCallStatus?.current === CallStatusType.Ringing) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [data.audioCallStatus?.current]);

  useEffect(() => {
    const stopSearchRetryOnBookingCall = () => {
      if (location?.state?.bookingId) {
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: data.audioCallStatus?.current!,
            current: CallStatusType.Zero,
          })
        );
        //console.trace("Redirecting:");
        history.push(AppRouteUi.Jobs.Root);
      }
    };

    if (
      data.audioCallStatus?.current === CallStatusType.UserNotFound ||
      data.audioCallStatus?.current === CallStatusType.Missed ||
      data.audioCallStatus?.current === CallStatusType.Rejected ||
      (data.audioCallStatus?.current === CallStatusType.Searching &&
        data.audioCallStatus?.prev !== CallStatusType.Zero)
    ) {
      stopSearchRetryOnBookingCall();
    }
  }, [data.audioCallStatus, location?.state?.bookingId, dispatch, history]);

  return (
    <>
      {data.audioCallStatus?.current == CallStatusType.Ringing ? (
        <audio
          src={AssetsAudio.DialTone}
          autoPlay
          onEnded={onEndedPlay}
          ref={audioRef} 
        />
      ) : (
        <></>
      )}
      <div className="site-call-page ">
        <div className="site-call-page-wrapper">
          <div className="container-fluid h-100">
          

            <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
              <div className="col-12 text-center d-flex align-items-center justify-content-center pt-3">
                <ul className=" babelCallAnimation bg-white caller-image">
                  {data.audioCallStatus?.current == CallStatusType.Searching ? (
                    <img
                      src={AssetsPng.SearchAnimation}
                      alt="searching"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={`${FileBucketURL.Root}${receiverId}?${imageView.fileTimeStamp}`}
                      alt=""
                      onError={(ev) => {
                        defaultImageLoad(
                          ev,
                          DefaultImageType.UserProfile,
                          '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                        );
                      }}
                      className="img-fluid"
                    />
                  )}
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
              {data.audioCallStatus?.current == CallStatusType.Completed && (
                <div className="col-12 text-center p-3 text-white">
                  <h5 className="my-4 font-500">
                    Preparing Call Summary. Please wait...
                  </h5>
                </div>
              )}
              {data.audioCallStatus?.current !== CallStatusType.Completed &&
                data.audioCallStatus?.prev !== CallStatusType.Established && (
                  <>
                    <div className="col-12 text-center p-3 text-white">
                      {isAccepted ? (
                        <p className="mb-3 h5">
                          <CallDurationCounter
                            participantState={participantState}
                          />
                        </p>
                      ) : (
                        <>
                          <p className="mb-3 pb-3">Outgoing OPI call</p>
                          {data.audioCallStatus?.current ==
                          CallStatusType.UserNotFound ? (
                            'The user you have called is not available at the moment. Please try again later'
                          ) : data.audioCallStatus?.current ==
                            CallStatusType.OperatorNotFound ? (
                            messageOperatorNotFound
                          ) : (
                            <h4 className="m-0 py-4">
                              {data.audioCallStatus?.current
                                ? CallStatusType[data.audioCallStatus?.current]
                                : ''}
                            </h4>
                          )}
                        </>
                      )}
                      {data.audioCallStatus?.current !==
                        CallStatusType.Searching &&
                        data.audioCallStatus?.current !==
                          CallStatusType.OperatorNotFound && (
                          <>
                            <p className="pb-3 mb-3 mt-0 h4 font-400">
                              {dialUser.name}
                            </p>
                            {
                              !isCalleeOperator && (
                                <p className="mb-3  mt-0 h5 font-500">{dialUser.companyName}</p>
                              )
                            }
                          </>
                        )}
                    </div>

                    <div className="h-15 w-100">
                      <NetworkConnection hangupHandler={audioCallCompleted} />
                    </div>

                    <div className="col-12 text-center pb-3">
                      <div className="d-flex align-items-center justify-content-center container">
                        {data.audioCallStatus?.current !==
                          CallStatusType.Searching && (
                          <>
                            <button
                              className="btn  mx-4  callPage-controlBtn btn-red"
                              disabled={!callId}
                              onClick={() => {
                                if (
                                  data.audioCallStatus?.current ==
                                    CallStatusType.Established &&
                                  data.audioCallStatus?.prev ==
                                    CallStatusType.Ringing
                                ) {
                                  setShow(true);
                                } else {
                                  audioCallCancelled();
                                  logger({ message: 'Call cancel button pressed' });
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                              </svg>
                            </button>
                            <ModalHangupCall
                              handleShow={setShow}
                              onSubmit={() => {
                                logger({ message: 'Call hangup button pressed' });
                                audioCallCompleted();
                              }}
                              show={show}
                              header={'Hangup Call'}
                            />
                            {isAccepted && (
                              <>
                                {isAudioEnabled ? (
                                  <button
                                    type="button"
                                    className="btn mx-4 callPage-controlBtn btn-green btn-audio-on"
                                    onClick={() => {
                                      setAudioEnabled(false);
                                      device.activeConnection()?.mute(true);
                                    }}
                                  >
                                    <AssetSvg.AudioOn />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn mx-4 callPage-controlBtn btn-red btn-audio-off"
                                    onClick={() => {
                                      setAudioEnabled(true);
                                      device.activeConnection()?.mute(false);
                                    }}
                                  >
                                    <AssetSvg.AudioOff />
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
