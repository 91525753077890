import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function AccessDenied() {
  return (
    <Container fluid className="vh-100 d-flex align-items-center justify-content-center bg-white">
      <Row>
        <Col className="text-center">
          <h1 className="display-4">Access Denied</h1>
          <p>You do not have permission to access this application.</p>
        </Col>
      </Row>
    </Container>
  );
};

