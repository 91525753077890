import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormErrorMessage } from "../../components";
import { ApiSchemaCallCenterMessageData, ApiSchemaRequestUpdateCallCenterMessage, MessageModel, MessageViewModel } from "../../models";
import { useFunctionalityCallCenterMessage, useFunctionalityCountry, useFunctionalityState } from '../../pages/hooks';

type Props = {
  show: boolean;
  handleShow: (value: boolean) => void;
 
  dataToEdit: MessageViewModel;
  setDataToEdit: (value: MessageViewModel) => void;
  onSaveHandler: (value: FormData, category?: string) => void;
};


export function ModalCallCenterMessageEditModal(props: Props): JSX.Element {
  // console.log("ModalCallCenterMessageEditModal has been shown");
  const {   show, handleShow, dataToEdit, setDataToEdit,onSaveHandler } = props;
  const { countryList } = useFunctionalityCountry();
 
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<MessageModel>();
  const modalHideHandler = () => {
    handleShow(false);
  }
 
  const onEditHandler = (form: any) => {
    form = {
      ...form,
      id: dataToEdit.id,
      title: dataToEdit.title,
      audioMediaKey: dataToEdit.audioMediaKey,
      category: dataToEdit.category,
      isDefault: dataToEdit.isDefault,
      isTtsEnabled: dataToEdit.isTtsEnabled,
    };
    const body = new FormData();
    body.append("id", form.id);
    body.append("title", form.title);
    body.append("audioMediaKey", form.audioMediaKey);
    body.append("category", form.category);
    body.append("isDefault", form.isDefault);
    body.append("isTtsEnabled", form.isTtsEnabled);

    if (form.file?.length > 0) {
      body.append("mediaFile", form.file[0]);
    }
    else
    {
      body.append("message", form.message);
    }
    onSaveHandler(body, dataToEdit?.category);
  };
  return (
    <Modal show={props.show} onHide={() => props.handleShow(false)} centered>
      <form onSubmit={handleSubmit(onEditHandler)} >
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>{dataToEdit?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className=" form-group">
                <label htmlFor="" className="required" > Text message </label>
                <div className="">
                  <textarea
                    {...register('message', { required: true })}
                    rows={3}
                    cols={50}
                    maxLength={300}
                    className="form-control"
                    defaultValue={dataToEdit?.message} />
                  {
                    errors.message && (
                      <FormErrorMessage message="required" />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn text-uppercase" type="button" onClick={modalHideHandler}>Cancel</button>
          <button type="submit" className=" btn btn-primary text-uppercase btn-message-submit">Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
