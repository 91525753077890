import React, {useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import { logger } from '../../../utils';
import { Spinner } from '../../../assets/Spinner';

type Props = {
  pageSize?: number;
  pageIndex?: number;
  count?: number;
  onChangePageSize?: any;
  onChangePageIndex?: any;
};

export function Pagination(props: Props): JSX.Element {
  const { pageSize = 10, pageIndex, count = 10, onChangePageIndex, onChangePageSize, } = props;
  const pageNumbers = Math.ceil(count / pageSize);
  const pageSizeOptions = [10, 20, 30, 50, 100, 1000];


  const handlePageClick = (event: any) => {
    // logger({ message: "__________________________________Pagination, selected page no: ", data: parseInt(`${event.selected}`) + 1});
    onChangePageIndex(parseInt(`${event.selected}`) + 1);
  };

  // useEffect(()=>{
  //   logger({ message: '__________________________________from Pagination:'});
  //   console.log("pageSize is: ", pageSize);
  //   console.log("pageIndex is: ", pageIndex);
  //   console.log("count is: ", count);
  //   console.log("pageNumbers is: ", pageNumbers);
  // }, [pageNumbers, pageSize, pageIndex, count]);


  return (
    <div className="col-12 mt-2 mb-2 pb-1">
      <div className="row align-items-start">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <div className="d-flex align-items-center flex-wrap">
            <div className="dropdown show">
              <a
                className="btn border-grey dropdown-toggle bg-white"
                role="button"
                id="page-count-filter"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {pageSize}
              </a>
              <div
                className="dropdown-menu page-counter p-0"
                aria-labelledby="page-count-filter"
              >
                {
                  pageSizeOptions.map((size: number, index) => (
                    <a
                      key={index}
                      className={"dropdown-item "}
                      onClick={() => {
                        onChangePageSize(size);
                      }}
                    >
                      {size}
                    </a>
                  ))
                }
              </div>
            </div>
            <div>
              <p className="mb-0  mr-auto mr-auto ml-3 ml-sm-3 ml-lg-2 ml-md-3 text-muted">
                {" "}
                Results per page
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <nav aria-label=" table Page navigation">
            {(!pageIndex || (pageIndex > 1 && count === 0)) ? (
              <div className="d-flex align-items-end justify-content-end">
              <Spinner />
            </div>
          ) : (            
          <ul className="pagination justify-content-sm-end">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageNumbers}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageIndex ? pageIndex - 1 : 0}
            // renderOnZeroPageCount={null}
            />
          </ul>)}

          </nav>
        </div>
      </div>
    </div>
  );
}